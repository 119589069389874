<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <NavComponent></NavComponent>
    <b-overlay :show="show" rounded="sm">
      <div class="row mx-5">
        <div class="col-md-4">
          <div class="card text-bg-warning mb-3">
            <div class="row g-0">
              <div class="col-md-6 text-center">
                <ion-icon
                  style="font-size: 80px"
                  name="list-circle-outline"
                  class="mx-4 mt-4 mb-2"
                ></ion-icon>
              </div>
              <div class="col-md-6">
                <p class="card-text mt-2 px-0 mb-0" style="font-size: 25px">
                  จำนวนบิลวันนี้
                </p>

                <b style="font-size: 50px"> {{ dash.today_total_order }} </b>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-bg-light mb-3">
            <div class="row g-0">
              <div class="col-md-6 text-center">
                <ion-icon
                  style="font-size: 80px"
                  name="pricetags"
                  class="mx-4 mt-4 mb-2"
                ></ion-icon>
              </div>
              <div class="col-md-6">
                <p class="card-text mt-2 px-0 mb-0" style="font-size: 25px">
                  ยอดขายวันนี้
                </p>

                <b style="font-size: 50px"> ฿ {{ dash.today_total_price }} </b>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card text-bg-success mb-3">
            <div class="row g-0">
              <div class="col-md-6 text-center">
                <ion-icon
                  style="font-size: 80px"
                  name="cash-outline"
                  class="mx-4 mt-4 mb-2"
                ></ion-icon>
              </div>
              <div class="col-md-6">
                <p class="card-text mt-2 px-0 mb-0" style="font-size: 25px">
                  กำไรวันนี้
                </p>

                <b style="font-size: 50px">
                  ฿ {{ dash.today_total_revenue }}
                </b>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4"><DoughnutChart /></div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import NavComponent from "@/components/NavComponent.vue";
import DoughnutChart from "@/components/Doughnut.vue";

import axios from "axios";
export default {
  name: "HomeView",
  components: {
    // HelloWorld,
    NavComponent,
    DoughnutChart,
  },
  data() {
    return {
      show: false,
      dash: [],
    };
  },
  created() {
    this.getDataToday();
  },
  methods: {
    getDataToday() {
      this.show = true;
      axios
        .get("/dashboard/today", {
          params: {
            date: "2023-10-30",
          },
        })
        .then((response) => {
          console.log(response.data.data[0]);
          this.dash = response.data.data[0];
          this.show = false;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด \nกรุณาเข้าสู่ระบบอีกครั้ง",
              text: error.response.data.message,
            });
            this.$router.push("/login");
            this.show = false;
          }
        });
    },
  },
};
</script>
