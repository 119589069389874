import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginView.vue"),
  },

  //! products
  {
    path: "/products",
    name: "productsindex",
    component: () => import("../views/products/ProductView.vue"),
  },
  {
    path: "/product/add",
    name: "AddProduct",
    component: () => import("../views/products/add.vue"),
  },
  {
    path: "/product/edit/:product_id",
    name: "editProduct",
    component: () => import("../views/products/edit.vue"),
  },

  //! branchs
  {
    path: "/branchs",
    name: "branchsindex",
    component: () => import("../views/branchs/index_branchs.vue"),
  },
  {
    path: "/branch/add",
    name: "AddBranch",
    component: () => import("../views/branchs/add_branch.vue"),
  },
  {
    path: "/branch/edit/:branch_id",
    name: "editBranch",
    component: () => import("../views/branchs/edit_branch.vue"),
  },

  //! dealers
  {
    path: "/dealers",
    name: "dealersindex",
    component: () => import("../views/dealers/index_dealers.vue"),
  },
  {
    path: "/dealer/add",
    name: "AddDealer",
    component: () => import("../views/dealers/add_dealer.vue"),
  },
  {
    path: "/dealer/edit/:dealer_id",
    name: "editDealer",
    component: () => import("../views/dealers/edit_dealer.vue"),
  },

  //! POS Page
  {
    path: "/pos",
    name: "pos_view",
    component: () => import("../views/pos/pos_view.vue"),
  },

  {
    path: "/saleHistory",
    name: "sale_history",
    component: () => import("../views/sale_history.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
