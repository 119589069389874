<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary mb-4">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">หน้าหลัก ร้านวังน้ำทิพย์</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/pos"
              ><b>ขายสินค้า</b>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="/saleHistory"
              ><b>ประวัติการขาย</b>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/products">ข้อมูลสินค้า</a>
          </li>

          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              ตั้งค่า
            </a>
            <ul class="dropdown-menu">
              <li class="dropdown-item">
                <a class="dropdown-item" href="/branchs">ตั้งค่าสาขา</a>
              </li>

              <li class="dropdown-item">
                <a class="dropdown-item" href="/staffs">ตั้งค่าพนักงาน</a>
              </li>
              <li class="dropdown-item">
                <a class="dropdown-item" href="/dealers">ตั้งค่าดีลเลอร์</a>
              </li>
              <li class="dropdown-item">
                <a class="dropdown-item" href="/customers">ตั้งค่าลูกค้า</a>
              </li>
              <li class="dropdown-item">
                <a class="dropdown-item" href="/product-stock"
                  >ตั้งค่าสต๊อกสินค้า / เช็คสต๊อก</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ nav_name }} (
              {{ nav_role === "admin" ? "เจ้าของกิจการ" : "พนักงาน" }} )
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" @click="logout()">ออกจากระบบ</a>
              </li>
            </ul>
          </li>

          <li class="d-flex">
            วันที่ : {{ date_now }} <br />เวลา : {{ currentTime }}
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link disabled" aria-disabled="true">Disabled</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      nav_name: "",
      nav_role: "",
      date_now: "",
      currentTime: new Date().toLocaleTimeString(),
      nav_access_branch: [],
    };
  },
  created() {
    this.checkrole();
    this.getdate();
  },
  mounted() {
    this.updateTime();
  },
  methods: {
    updateTime() {
      setInterval(() => {
        // Update the current time every second
        this.currentTime = new Date().toLocaleTimeString();
      }, 1000);
    },
    logout() {
      localStorage.removeItem("user_data");
      localStorage.removeItem("authtoken");
      this.$router.push("/login");
    },
    checkrole() {
      if (!localStorage.getItem("user_data")) {
        localStorage.removeItem("user_data");
        localStorage.removeItem("authtoken");
        this.$router.push("/login");
      } else {
        const userdata = JSON.parse(localStorage.getItem("user_data"));
        // console.log(userdata);
        this.nav_name = userdata.name;
        this.nav_role = userdata.role;
        this.nav_access_branch = userdata.access_branch;
      }
    },
    getdate() {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to the month because it's 0-based
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      // console.log(formattedDate);
      this.date_now = formattedDate;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
