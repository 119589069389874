import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSweetalert2 from "vue-sweetalert2";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

Vue.use(VueSweetalert2);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

axios.defaults.baseURL = "http://localhost:8000/api";
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authtoken");
    // const token = "HuS70FVcNwohC9HmAUKt6EtNMzdLisKemYYiVn1L700e9066";

    if (token) {
      config.headers.Accept = "application/json";
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
window.Vue = Vue;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
